import { ChevronForwardOutline } from 'react-ionicons'

import { Container } from '@/components/utils'
import { Image } from '@/components/elements/Image'
import { Button } from '@/components/elements/Button'
import { IonIcon } from '@/src/icons'

export interface CTASectionProps {
  title: string
  subtitle: string
  ctaUrl?: string
  ctaLabel?: string
}

export const CTASection: React.FC<CTASectionProps> = ({
  title,
  subtitle,
  ctaUrl,
  ctaLabel,
}) => {
  return (
    <section className="relative py-24">
      <div className="absolute z-0 inset-0 pointer-events-none">
        <Image
          src="/img/cta-background.jpeg"
          alt=""
          layout="fill"
          objectFit="cover"
          className="opacity-30"
        />
        <div className="absolute inset-0 bg-navy mix-blend-multiply" />
      </div>
      <Container className="relative z-10 flex flex-col items-center justify-between text-white sm:flex-row">
        <div className="text-center sm:text-left">
          <h2 className="mb-4 font-display text-3xl font-black leading-none sm:text-4xl lg:text-5xl">
            {title}
          </h2>
          {subtitle && (
            <p className="font-display font-black sm:text-3xl">{subtitle}</p>
          )}
        </div>
        {ctaLabel && ctaUrl && (
          <div className="flex justify-center mt-10 sm:mt-0">
            <Button
              href={ctaUrl}
              label={ctaLabel}
              icon={<IonIcon icon={ChevronForwardOutline} />}
            />
          </div>
        )}
      </Container>
    </section>
  )
}
