import cn from 'classnames'
import { Button, ButtonProps } from '@/components/elements/Button'

export interface SectionTitleProps {
  title: string
  subtitle?: string
  marginBottom?: boolean
  cta?: ButtonProps
  variant?: 'default' | 'text-left'
  className?: string
}

export const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  subtitle,
  marginBottom = true,
  variant = 'default',
  className = '',
  cta,
}) => (
  <div
    className={cn(
      className,
      marginBottom && 'mb-20',
      variant === 'default' && 'text-center',
      variant === 'text-left' && 'text-center sm:text-left'
    )}
  >
    <h2 className="mb-4 font-display text-3xl font-black leading-none sm:text-4xl lg:text-5xl">
      {title}
    </h2>
    {subtitle && (
      <p className="text-center text-navy-light-700 text-lg md:px-20">
        {subtitle}
      </p>
    )}
    {cta && (
      <div className="mt-10">
        <Button {...cta} />
      </div>
    )}
  </div>
)
