import dayjs from 'dayjs'
import 'dayjs/locale/ca'

import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

dayjs.tz.setDefault('Europe/Madrid')
dayjs.locale('ca')

const formatDate = (isoString: string): string => {
  return dayjs.utc(isoString).tz().format('DD/MM/YYYY')
}

export const formatDateText = (isoString: string): string => {
  return dayjs.utc(isoString).tz().format('MMMM D, YYYY')
}

export const formatDateTime = (isoString: string): string => {
  return dayjs.utc(isoString).tz().format('HH:mm [h] | DD/MM/YYYY')
}
export const formatDateAndMonth = (isoString: string): string => {
  return dayjs.utc(isoString).tz().format('MMMM YYYY')
}

export const formatMonth = (isoString: string): string => {
  return dayjs.utc(isoString).tz().format('MMMM')
}

export default formatDate
