import { GetStaticPropsResult } from 'next'
import React, { ReactElement } from 'react'
import { getMovies, getUpcomingMovies } from 'src/movies'
import { PageMeta } from 'src/pages'
import { MapIcon } from '@heroicons/react/outline'
import { Layout } from '@/components/Layout'
import { Hero, HeroProps } from '@/components/patterns/Hero'
import homeData from '@/content/home.json'

import { getCinemas } from '@/src/cinemas'
import { getNews, OneNews } from '@/src/noticies'
import { CinemaMap } from '@/components/patterns/CinemaMap'
import { Cinema, Movie } from '@/src/types'
import { CTASection, CTASectionProps } from '@/components/patterns/CtaSection'
import { PromotedMovies } from '@/components/patterns/MovieSummary'
import {
  NewsletterSection,
  NewsletterSectionProps,
} from '@/components/patterns/NewsletterSection'
import { HomeNews } from '@/components/patterns/HomeNews'
import { SectionTitle } from '@/components/elements/SectionTitle'
import { IonIcon } from '@/src/icons'
import dynamic from 'next/dynamic'

const MovieCard = dynamic(() => import('@/components/patterns/MovieCard').then(module => module.MovieCard), { 
  ssr:false 
})

interface HomeProps {
  // eslint-disable-next-line react/no-unused-prop-types
  meta: PageMeta
  hero: HeroProps
  promotedMovie: Movie
  cta: CTASectionProps
  newsletter: NewsletterSectionProps
  cinemas: Cinema[]
  upcomingMovies: Movie[]
  news: OneNews[]
}

export async function getStaticProps(): Promise<
  GetStaticPropsResult<HomeProps>
> {
  const title = `Cinexic`
  const description = 'Test description'
  const movies = await getMovies()
  const cinemas = await getCinemas()
  const news = await getNews(2)

  const promotedMovie = movies.find(
    (movie) => movie.id === homeData.promotedMovie
  )
  const upcomingMovies = getUpcomingMovies()

  upcomingMovies.sort((a: Movie, b: Movie) => {
    return (
      new Date(a.projections[0].date).getTime() -
      new Date(b.projections[0].date).getTime()
    )
  })

  return {
    props: {
      meta: {
        title,
        description,
      },
      ...homeData,
      cinemas,
      promotedMovie,
      upcomingMovies,
      news,
    },
  }
}

export default function Home({
  promotedMovie,
  upcomingMovies,
  meta,
  hero,
  cta,
  newsletter,
  cinemas,
  news,
}: HomeProps): ReactElement {
  return (
    <Layout meta={meta}>
      <Hero {...hero} />
      {promotedMovie &&
        <MovieCard movie={promotedMovie} showMaterialsButton={false} />
      }
      <SectionTitle
        cta={{
          label: 'Consulta les sales',
          icon: <IonIcon icon={MapIcon} />,
          href: '/cinemes',
        }}
        title="Troba la sala més propera"
        subtitle="Llistat de sales CineXic"
      />
      <CinemaMap cinemas={cinemas} />
      <CTASection {...cta} />
      <PromotedMovies movies={upcomingMovies} />
      <NewsletterSection {...newsletter} />
    </Layout>
  )
}
