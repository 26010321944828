import classNames from 'classnames'
import { ErrorMessage } from '@hookform/error-message'
import { AlertCircleOutline } from 'react-ionicons'
import { ErrorMessageWrapper } from '@/components/elements/ErrorMessageWrapper'
import { IonIcon } from '@/src/icons'

export interface InputProps {
  isTextArea?: boolean
  variant?: 'primary' | 'secondary'
  id: string
  name: string
  label?: string
  placeholder?: string
  className?: string
  register: any
  validationRules?: Record<string, any>
  errors?: any
  [key: string]: any
}

export const Input: React.FC<InputProps> = ({
  isTextArea = false,
  variant = 'primary',
  label,
  id,
  name,
  register,
  validationRules = {},
  errors,
  placeholder = '',
  className = '',
  ...rest
}) => {
  return (
    <>
      <div className={classNames('flex flex-row', className)}>
        {errors && errors[name] && (
          <IonIcon
            className="-ml-7 mr-2 w-5 h-5 text-attention"
            icon={AlertCircleOutline}
          />
        )}
        <div className="flex flex-col flex-grow">
          {label && (
            <label htmlFor={id} className="leading-1 pb-1 font-semibold">
              {label}
            </label>
          )}
          {isTextArea && (
            <textarea
              id={id}
              {...register(name)}
              placeholder={placeholder}
              className={classNames(
                variant === 'secondary'
                  ? 'text-navy-700 bg-white'
                  : 'text-navy-900 bg-navy-light-50',
                'placeholder-gray p-3 px-2 border-0 focus:border-notifiers-focus rounded focus:outline-none focus:ring',
                errors && errors[name] && 'border-attention'
              )}
              {...rest}
            />
          )}
          {!isTextArea && (
            <input
              id={id}
              {...register(name, validationRules)}
              placeholder={placeholder}
              className={classNames(
                variant === 'secondary'
                  ? 'text-navy-700 bg-white'
                  : 'text-navy-900 bg-navy-light-50',
                'placeholder-gray p-3 px-2 border-0 focus:border-notifiers-focus rounded focus:outline-none focus:ring',
                errors && errors[name] && 'border-attention'
              )}
              {...rest}
            />
          )}
          {errors && (
            <ErrorMessage
              errors={errors}
              name={name}
              as={ErrorMessageWrapper}
            />
          )}
        </div>
      </div>
    </>
  )
}
