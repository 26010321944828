import React from 'react'
import { ArrowForwardOutline } from 'react-ionicons'
import { Link } from '@/components/types'
import { Button } from '@/components/elements/Button'
import { Image } from '@/components/elements/Image'
import { H2 } from '@/components/elements/Headers'
import { IonIcon } from '@/src/icons'

export interface HeroProps {
  title: string
  subtitle: string
  picture: string
  cta: Link
}

export const Hero: React.FC<HeroProps> = ({
  title,
  subtitle,
  picture,
  cta: { href, label },
}) => (
  <>
    <div className="lg:flex">
      <div className="header-bg-image hero-image aspect-w-4 aspect-h-3 relative flex-1 lg:block lg:pb-0">
        <Image
          className="z-1 pointer-events-none lg:absolute lg:inset-0"
          src={picture}
          layout="fill"
          objectPosition="center"
          objectFit="cover"
          priority
          quality={55}
          alt=""
        />
      </div>
      <div className="p-10 bg-omnium lg:w-100 lg:max-w-2xl lg:min-h-160">
        <div className="w-full max-w-xl font-body leading-none lg:max-w-4xl">
          <H2 className="text-white">{title}</H2>
          <p className="mt-10 text-white text-lg font-bold leading-7 lg:text-xl">
            {subtitle}
          </p>
          <div className="mt-10">
            <Button
              animateIcon
              icon={<IonIcon icon={ArrowForwardOutline} />}
              variant="outlined-inverted"
              href={href}
              label={label}
            />
          </div>
        </div>
      </div>
    </div>
  </>
)
