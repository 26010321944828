import {
  useGoogleMap,
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from '@react-google-maps/api'
import { useCallback, useState, useEffect } from 'react'
import { Link } from '@/components/elements/Link'
import { Cinema } from '@/src/types'

export interface CinemaMapProps {
  cinemas: Cinema[]
  zoom?: number
}

const containerStyle = {
  width: '100%',
  height: '100%',
}

export const CinemaMap: React.FC<CinemaMapProps> = ({ cinemas, zoom = 8 }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey:
      process.env.NEXT_GOOGLE_MAPS_API_KEY ||
      'AIzaSyAMC_q-OkmlV63zuctD7xyXjpgpEppW-Pg',
  })

  const getCenterCoordinates = (() => {
    let lat = 0
    let lng = 0

    cinemas.forEach((cinema) => {
      lat += cinema.coordinates.lat
      lng += cinema.coordinates.lng
    })

    return { lat: lat / cinemas.length, lng: lng / cinemas.length }
  })()

  const [map, setMap] = useState<google.maps.Map>(null)

  const onUnmount = useCallback(function callback() {
    setMap(null)
  }, [])

  const [selected, setSelected] = useState<Cinema>(null)

  return (
    <div className="aspect-w-10 aspect-h-5 2xl:aspect-h-3 xl:aspect-h-4 relative w-full">
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={getCenterCoordinates}
          zoom={zoom}
          onUnmount={onUnmount}
        >
          {cinemas.map(
            (cinema) =>
              cinema.coordinates?.lat && (
                <Marker
                  key={cinema.id}
                  position={cinema.coordinates}
                  onClick={() => setSelected(cinema)}
                >
                  {selected && selected.id === cinema.id && (
                    <InfoWindow onCloseClick={() => setSelected(null)}>
                      <div className="text-base">
                        <Link
                          href={`/cinemes/${cinema.id}`}
                          className="text-lg font-semibold"
                        >
                          {cinema.name}
                        </Link>
                        <div className="">{cinema.city}</div>
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              )
          )}
        </GoogleMap>
      )}
    </div>
  )
}
