import React from 'react'
import cn from 'classnames'
import { Image } from '@/components/elements/Image'
import { Movie } from '@/src/types'
import { Button } from '@/components/elements/Button'
import { formatMonth } from '@/utils/formatDate'
import { Divider } from '@/components/elements/Divider'
import { Container } from '@/components/utils'

export interface MovieSummaryrops {
  movie: Movie
  variant: 'text-left' | 'text-right'
}

export const MovieSummary: React.FC<MovieSummaryrops> = ({
  movie,
  variant,
}) => (
  <div className="grid gap-x-4 grid-cols-1 sm:grid-cols-7 sm:auto-rows-fr">
    <div className="aspect-w-1 aspect-h-1 md:aspect-w-1 md:aspect-h-1 sm:col-span-3">
      <Image
        className="absolute z-10 inset-0 pointer-events-none"
        src={movie.cover}
        layout="fill"
        objectPosition="center"
        objectFit="cover"
        priority
        quality={55}
        alt=""
      />
    </div>
    <div
      className={cn(
        'py-8 sm:py-32 px-6 sm:col-span-4',
        variant === 'text-left' && 'sm:order-first',
        variant === 'text-right' ? 'sm:pr-40 sm:pl-20' : 'sm:pl-40 sm:pr-20'
      )}
    >
      <p className="text-mustard font-body text-sm font-bold tracking-wider uppercase sm:text-base">
        PROPERAMENT: {formatMonth(movie.projections[0]?.date?.toString())}
      </p>
      <h3 className="my-6 break-words font-display text-4xl font-black leading-none sm:text-6xl">
        {movie.title}
      </h3>
      <p className="hidden mb-6 text-navy-700 font-body text-base md:block">
        {movie.description.length > 750
          ? `${movie.description.substring(0, 750)}...`
          : movie.description}
      </p>
      <Button href={`/pelicules/${movie.id}`} label="Més informació" />
    </div>
  </div>
)

export interface PromotedMoviesProps {
  movies: Movie[]
}

export const PromotedMovies: React.FC<PromotedMoviesProps> = ({ movies }) => {
  return (
    <>
      {movies.map((movie, index) => {
        return (
          <MovieSummary
            key={movie.id}
            movie={movie}
            variant={index % 2 === 0 ? 'text-left' : 'text-right'}
          />
        )
      })}
    </>
  )
}
