import cn from 'classnames'

export const H2: React.FC<{ className?: string }> = ({
  children,
  className = '',
}) => (
  <h2
    className={cn(
      'font-display text-5xl font-black lg:text-6xl leading-none',
      className
    )}
  >
    {children}
  </h2>
)

export const H3: React.FC<{ className?: string }> = ({
  children,
  className = '',
}) => (
  <h3
    className={cn(
      'font-display text-4xl font-black lg:text-5xl leading-none',
      className
    )}
  >
    {children}
  </h3>
)

export const H4: React.FC<{ className?: string }> = ({
  children,
  className = '',
}) => (
  <h3
    className={cn(
      'font-display text-2xl font-black lg:text-3xl leading-none',
      className
    )}
  >
    {children}
  </h3>
)
