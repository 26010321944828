import Image, { ImageProps } from 'next/image'
import cx from 'classnames'
import { motion, useAnimation } from 'framer-motion'

const myLoader = ({ src, width, quality }) => {
  return `https://cinexic.cat${src}?w=${width}&q=${quality || 75}`
}

function CustomImage(props: ImageProps): JSX.Element {
  const { layout, priority, onLoad = () => null } = props
  const controls = useAnimation()

  return (
    <motion.span
      className={cx({ 'absolute inset-0': layout === 'fill' })}
      initial={priority ? {} : { opacity: 0 }}
      animate={controls}
    >
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <Image
        {...props}
        loader={myLoader}
        onLoad={(event) => {
          controls.start({ opacity: 1 })
          onLoad(event)
        }}
      />
    </motion.span>
  )
}

export { CustomImage as Image }
